<template>
  <div class="spacer">
    <div class="noData" v-if="
      (viewerId != null && viewerId != '' && !currentViewer) ||
      brandId == null
    ">
      <!-- 无数据，请联系管理员
      <div style="font-size: 20px">
        错误：{{ !brandId ? '无品牌信息' : '模型项不存在或者已删除' }}
      </div> -->
    </div>
    <div v-else>
      <img v-if="viewerId == '' || viewerId == null || !currentViewer" class="brandLogoSelector" :src="brandLogoURL" />
      <!-- <img
        src="../../assets/color3D_logo.png"
        height="30"
        width="50"
        :class="mobileLayout && isViewer ? 'yituLogoMobile' : 'yituLogo'"
      /> -->
      <div class="modelSelector" v-if="viewerId == '' || viewerId == null || !currentViewer">
        <!-- <div class="industrySelectorArea">
          <div class="industrySelector">
            <div
              v-for="(industry, index) in industries"
              v-bind:key="index"
              @click="selectedIndustry = industry.value"
              :class="
                industry.value == selectedIndustry
                  ? 'selectedIndustry'
                  : 'unselectedIndustry'
              "
            >
              {{ industry.label }}
            </div>
          </div>
        </div> -->

        <div :class="!mobileLayout ? 'modelSelectionArea' : 'modelSelectionAreaMobile'
          ">
          <el-card class="modelSelectCard" v-for="(model, index) in filteredViewers" v-bind:key="index">
            <div @click="showViewer(model, true)">
              <div class="modelTitle">{{ model.viewerTitle }}</div>
              <img class="modelSelectImage" :src="model.picUrl" />
            </div>
          </el-card>
          <div class="empty" v-if="filteredViewers.length == 0">
            暂无此类模型
          </div>
        </div>
      </div>

      <div class="modelViewer" v-else>
        <div class="mobileTopMenu" v-if="mobileLayout">
          <div @click="backToMenu()" v-if="type != 'preview'" class="titleText">
            <i class="el-icon-d-arrow-left"></i>
          </div>
          <span class="titleText">{{ currentViewer.viewerTitle }}</span>
          <div style="font-size: 26px; margin-top: 3px; margin-right: 5px" @click="sharingWindowOpen = true">
            <i class="el-icon-share"></i>
          </div>

          <!-- <img
            src="../../assets/download.png"
            alt="DOWNLOAD"
            @click="downloadImage"
            height="35"
            width="35"
          /> -->
        </div>
        <div class="topMenu" v-else>
          <div class="backButton" @click="backToMenu()" v-if="type != 'preview'">
            <div class="backArrow"><i class="el-icon-d-arrow-left"></i></div>
            <div class="backButtonText">选择其他模型</div>
          </div>
          <div class="rightMenu">
            <div class="modelTitle">
              <span class="titleText">{{ currentViewer.viewerTitle }}</span>
            </div>
            <!-- 暂时不放 -->
            <!-- <img
              src="../../assets/download.png"
              alt="DOWNLOAD"
              class="downloadButton"
              @click="downloadImage"
            /> -->
          </div>
          <div></div>
        </div>

        <div v-if="sharingWindowOpen" class="sharingWindowContentBlocker"></div>
        <div v-if="sharingWindowOpen" class="sharingWindow">
          <div class="sharingTitle">
            <el-button icon="el-icon-close" class="sharingCloseButton" type="text"
              @click="sharingWindowOpen = false"></el-button>
            分享

            <vue-qr :text="link" :size="150" />
            <!-- 微信不支持复制链接，暂时放弃
              <el-button
              @click="copyLink()"
              size="small"
              type="text"
              class="sharingCopyLink"
              style="display: block; margin-left: 45px; margin-top: -10px"
              >{{ $t('copy_link') }}</el-button
            > -->
          </div>
        </div>

        <div class="arButton" @click="startAr()" v-if="isArAvailable">
          <!-- <div style="display: block; background-color: white; height: 35px; width: 35px"></div> -->
          <img src="../../assets/ar_logo.png" height="35" width="35" style="margin: 6px 5px 5px 15px" />
          <div style="display: inline-block; padding: 12px 15px 5px 0px">
            Try out in AR
          </div>
        </div>

        <div id="emersyaMainViewer" :style="{
          height: viewerHeight,
          width: !mobileLayout
            ? displayCustomize
              ? 'calc(100% - 315px)'
              : '100%'
            : '100%'
        }"></div>

        <div v-if="!hasInitiatedViewer" :style="{ height: viewerHeight }" class="iframeLoadingScreen">
          <div><i class="el-icon-loading"></i></div>
        </div>

        <!-- PC版本UI （选择颜色） -->
        <div v-if="!mobileLayout">
          <div class="closedCustomize" v-if="!displayCustomize">
            <i class="el-icon-edit-outline" @click="toggleCustomizeDisplay()">&nbsp;{{ $t('customize') }}</i>
          </div>
          <div class="materialSelectorArea" v-else>
            <div class="sectionTitle" style="cursor: pointer" @click="toggleCustomizeDisplay()">
              {{ $t('customize') }} <i class="el-icon-arrow-right"></i>
            </div>
            <div class="materialBox">
              <div class="groupSelectCard" v-for="(group, index) in materialGroups" v-bind:key="index">
                <div class="groupTitle" :style="{
                  fontWeight: index == selectedGroup ? 'bold' : undefined
                }" @click="clickedGroup(index)">
                  {{ $t(index) }}
                  <i :class="index == selectedGroup
                    ? 'el-icon-arrow-up'
                    : 'el-icon-arrow-down'
                    " style="position: absolute; right: 20px"></i>
                </div>
                <div class="materialArea" v-if="index == selectedGroup">
                  <el-card class="materialSelectCard" v-for="(material, jndex) in group" v-bind:key="jndex"
                    :body-style="{ padding: '5px' }" :style="{
                      border:
                        currentMaterials[index] == jndex
                          ? '2px solid #97c11c'
                          : undefined
                    }">
                    <div @click="selectMaterial(index, jndex)">
                      {{ $t(jndex) }}
                      <div class="materialPreview" :style="{
                        backgroundColor:
                          cachedProductList[extractFirst8Digits(jndex)].hex
                      }" v-if="cachedProductList[extractFirst8Digits(jndex)]"></div>
                      <div class="materialPreview" :style="{ backgroundColor: material.color }"
                        v-else-if="material.color && material.color != '#ffffff'"></div>
                      <img class="materialPreview" :src="material.image" v-else />
                    </div>
                  </el-card>
                </div>
              </div>
            </div>
            <!-- <div
              class="sectionTitle"
              v-if="
                !mobileLayout && brandId == 'dcf7bb23ebeb4509ac6905c6d29b24e6'
              "
            >
              产品信息
            </div>
            <div
              class="productInfoBox"
              v-if="
                !mobileLayout && brandId == 'dcf7bb23ebeb4509ac6905c6d29b24e6'
              "
            >
              <el-row class="productInfo">
                <el-col :span="9" class="productInfoKey">产品号</el-col>
                <el-col :span="15" class="productInfoValue">{{
                  this.currentProductNo
                }}</el-col>
              </el-row>
              <el-row class="productInfo">
                <el-col :span="9" class="productInfoKey">分类</el-col>
                <el-col :span="15" class="productInfoValue">{{
                  this.currentProductInfo.productCategory
                }}</el-col>
              </el-row>
              <el-row class="productInfo">
                <el-col :span="9" class="productInfoKey">表面</el-col>
                <el-col :span="15" class="productInfoValue">{{
                  this.currentProductInfo.surface
                }}</el-col>
              </el-row>
              <el-row class="productInfo">
                <el-col :span="9" class="productInfoKey">光泽</el-col>
                <el-col :span="15" class="productInfoValue">{{
                  this.currentProductInfo.gloss
                }}</el-col>
              </el-row>
              <el-row class="productInfo">
                <el-col :span="9" class="productInfoKey">性能标准</el-col>
                <el-col :span="15" class="productInfoValue">{{
                  this.currentProductInfo.productPerformance
                }}</el-col>
              </el-row>

              <div
                class="colorPreview"
                :style="{ backgroundColor: currentProductInfo.hex }"
              ></div>
            </div> -->
          </div>
        </div>
      </div>

      <div v-if="viewerId != '' && viewerId != null && currentViewer" class="modelSelectMenu">
        <!-- Phone版本UI （选择颜色）-->
        <div v-if="!mobileLayout">
          <div class="modelSelectMenuArrow" @click="toggleBottomBarModelsDisplay">
            <i class="el-icon-arrow-down" v-if="displayBottomModels"></i>
            <i class="el-icon-arrow-up" v-else></i>
            <div v-if="!displayBottomModels">显示所有模型</div>
          </div>
          <div class="modelSelectMenuBar"></div>
          <div v-if="displayBottomModels" class="container">
            <div class="modelSelectCard" v-for="(model, index) in filteredViewers" v-bind:key="index">
              <div @click="showViewer(model, true)">
                <div class="modelTitle">{{ model.viewerTitle }}</div>
                <img class="modelSelectImage" :src="model.picUrl" />
              </div>
            </div>
          </div>
        </div>

        <!-- 手机版本UI （选择模型）-->
        <div ref="mobileBottomSelectMenu" class="mobileBottomSelectMenu" v-else>
          <div style="position: relative">
            <div class="menuSegment" :style="{ bottom: displayMobileBottomColors ? '90px' : '0px' }">
              <div class="title" @click="toggleMobileBottomBarColorsDisplay">
                <i class="el-icon-arrow-up arrow" v-if="!displayMobileBottomColors"></i>
                <i class="el-icon-arrow-down arrow" v-else></i>
                {{ $t('color') }}
                <span v-if="selectedGroup && currentMaterials[selectedGroup]">: {{ $t(currentMaterials[selectedGroup])
                  }} ({{
                    $t(selectedGroup)
                  }})</span>
              </div>
              <div class="colorsContent" v-if="displayMobileBottomColors">
                <div class="flexBoxArea">
                  <div class="materialSelectCard" v-for="(material, jndex) in materialGroups[selectedGroup]"
                    v-bind:key="jndex" :style="{
                      border:
                        currentMaterials[selectedGroup] == jndex
                          ? '2px solid #97c11c'
                          : undefined
                    }">
                    <div @click="selectMaterial(selectedGroup, jndex)">
                      <div class="materialPreview" :style="{
                        backgroundColor:
                          cachedProductList[extractFirst8Digits(jndex)].hex
                      }" v-if="cachedProductList[extractFirst8Digits(jndex)]"></div>
                      <div class="materialPreview" :style="{ backgroundColor: material.color }" v-else-if="
                        material.color && material.color != '#ffffff'
                      "></div>
                      <img class="materialPreview" :src="material.image" v-else-if="material.image" />
                      <div class="materialPreview" style="font-size: 12px; overflow: hidden" v-else>
                        {{ jndex }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flexBoxArea">
                  <div :class="index == selectedGroup
                    ? 'groupSelectCard selected'
                    : 'groupSelectCard'
                    " v-for="(group, index) in materialGroups" v-bind:key="index">
                    <div class="groupTitle" @click="clickedGroup(index, true)">
                      {{ $t(index) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="menuSegment">
              <div class="title" @click="toggleMobileBottomBarModelsDisplay">
                <i class="el-icon-arrow-up arrow" v-if="!displayMobileBottomModels"></i>
                <i class="el-icon-arrow-down arrow" v-else></i>
                3D模型
                <span v-if="hasInitiatedViewer">: {{ currentViewer.viewerTitle }}</span>
              </div>
              <div class="modelsContent" v-if="displayMobileBottomModels">
                <div class="modelSelectCard" v-for="(model, index) in filteredViewers" v-bind:key="index">
                  <div @click="showViewer(model, true)">
                    <img class="modelSelectImage selected" :src="model.picUrl" v-if="viewerId == model.viewerId" />
                    <img class="modelSelectImage" :src="model.picUrl" v-else />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {
  getBrandLogoApi,
  getViewerInfoApi,
  getProductInfoBulkApi,
  getAllMaterialGroupsApi,
  addClick,
  emersyaLogin,
  emersyaLogout
} from '@/api/modules/modelViewer'
import VueQr from 'vue-qr'
export default {
  data() {
    return {
      mobileLayout: false,
      presetConfigurationName: [],
      presetGroupName: [],
      brandId: '',
      emersyaSessionId: '',
      previousViewerId: '',
      viewerId: '',
      selectedGroup: '',
      type: '',
      industries: [
        { value: '0', label: '交通运输' },
        { value: '1', label: '建筑建材' },
        { value: '2', label: '通用工业' },
        { value: '3', label: '绿色生活' },
        { value: '4', label: '其他' }
      ],
      selectedIndustry: '0',
      viewerHeight: 0,
      hasInitiatedViewer: false,
      viewerActive: false,
      previousMaterialGroupInfo: null,
      brandLogoURL: '',
      viewers: [],
      materialGroups: [],
      currentMaterials: {},
      currentProductNo: 'Loading...',
      currentProductInfo: {
        productCategory: '',
        surface: '',
        gloss: '',
        productPerformance: '',
        hex: ''
      },
      productInfoExpanded: false,
      sharingWindowOpen: false,
      isArAvailable: false,
      displayBottomModels: false,
      displayMobileBottomModels: false,
      displayMobileBottomColors: false,
      displayCustomize: true,
      cachedModels: [],
      timer: null,
      cachedProductList: {}
    }
  },
  components: {
    VueQr
  },
  computed: {
    filteredViewers() {
      if (this.viewers.length > 0) {
        // return this.viewers.filter(
        //   (viewer) =>
        //     viewer.industry == this.selectedIndustry && viewer.status == '1'
        // )
        return this.viewers
          .filter((viewer) => viewer.status == '1')
          .sort(function (a, b) {
            let x = a.industry.toLowerCase()
            let y = b.industry.toLowerCase()
            if (x < y) {
              return -1
            }
            if (x > y) {
              return 1
            }
            return 0
          })
      } else {
        return []
      }
    },
    currentViewer() {
      return this.viewers.filter(
        (viewer) => viewer.viewerId == this.viewerId
      )[0]
    },
    isViewer() {
      return !(
        this.viewerId == '' ||
        this.viewerId == null ||
        !this.currentViewer
      )
    },
    emersyaId() {
      return this.isViewer ? this.currentViewer.emersyaId : ''
    },
    projectCode() {
      return this.isViewer ? this.currentViewer.projectCode : ''
    },
    topBarHeight() {
      return this.mobileLayout ? '36' : '0'
    },
    isAnyMobileBottomMenuOpen() {
      return this.displayMobileBottomModels || this.displayMobileBottomColors
    },
    bottomBarHeight() {
      if (this.mobileLayout) {
        let height = this.$refs.mobileBottomSelectMenu.clientHeight
        if (this.isAnyMobileBottomMenuOpen) {
          height += 90
        }
        return height
      }
      return this.displayBottomModels ? '120' : '7'
    },
    link() {
      return window.location.href
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.brandId = this.$route.query.brandId
    this.viewerId = this.$route.query.viewerId
    this.type = this.$route.query.type

    this.presetConfigurationName = this.$route.query.configurationName
      ? decodeURI(this.$route.query.configurationName).split(',')
      : []
    this.presetGroupName = this.$route.query.groupName
      ? decodeURI(this.$route.query.groupName).split(',')
      : []

    emersyaLogin().then(({ data }) => {
      if (data && data.code === 0) {
        console.log(data)
        this.emersyaSessionId = data.sessionId
      }
    })
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)

    emersyaLogout({
      sessionId: this.emersyaSessionId
    }).then(({ data }) => {
      if (data && data.code === 0) {
        console.log(data)
        this.emersyaSessionId = ''
      }
    })
  },
  created() {
    document.title = '在线渲染'
    this.getBrandLogo()
    this.getViewerInfo()

    document.addEventListener(
      'emersyaViewerInitialized',
      this.initViewer,
      false
    )
  },
  methods: {
    initViewer() {
      this.startTimer()
      this.addClick(this.viewerId)
      // 没有导航兰的话，要改啊
      this.viewerHeight =
        window.innerHeight - this.topBarHeight - this.bottomBarHeight + 'px'

      this.hasInitiatedViewer = true

      this.viewerActive = true
      this.getAllMaterialGroups()

      emersyaViewer = emViewers['emersyaMainViewer']

      // set global listeners

      emViewers['emersyaMainViewer'].addEventListener(
        'onStateChange',
        (data) => {
          if (data.viewerState == 'loaded') {
            this.getIsArAvailable()

            // If there are presets, apply them
            if (
              this.presetConfigurationName.length > 0 &&
              this.presetGroupName.length > 0
            ) {
              this.presetConfigurationName.forEach((item, index) => {
                this.selectMaterial(
                  this.presetGroupName[index],
                  this.presetConfigurationName[index]
                )
              })
            }
            // Otherwise, just get the defaults
            else {
              console.log('Getting current material groups')
              this.getCurrentMaterialGroups()
            }
          }
        }
      )

      emViewers['emersyaMainViewer'].addEventListener(
        'onConfigurableMaterialHighlight',
        (data) => {
          let material = data.length > 0 ? data[0] : ''

          emViewers['emersyaMainViewer'].setHighlight({
            meshes: [],
            configurableMaterials: [material],
            configurableMaterialGroups: []
          })
          this.resetTimer()
        },
        false
      )
      emViewers['emersyaMainViewer'].addEventListener(
        'onMeshSelection',
        (data) => {
          console.log(data)
          if (data.configurableMaterialsGroupName[0]) {
            this.selectedGroup = data.configurableMaterialsGroupName[0]
            console.log(this.selectedGroup)
          } else {
            console.log('Selected non-configurable')
          }
        },
        false
      )
    },
    handleResize() {
      if (this.$refs.mobileBottomSelectMenu) {
        console.log(this.bottomBarHeight, 'height')
      }
      this.viewerHeight =
        window.innerHeight - this.topBarHeight - this.bottomBarHeight + 'px'
      this.mobileLayout = window.innerWidth <= 818
    },
    getBrandLogo() {
      this.$nextTick(() => {
        getBrandLogoApi({ brandId: this.brandId }).then(({ data }) => {
          if (data && data.code === 0) {
            this.brandLogoURL = data.logoPath
          }
        })
      })
    },
    getViewerInfo() {
      this.$nextTick(() => {
        getViewerInfoApi({ brandId: this.brandId }).then(({ data }) => {
          if (data && data.code === 0) {
            this.viewers = data.viewers
            if (this.viewerId) {
              this.showViewer(this.currentViewer)
            }
          }
        })
      })
    },
    addClick(viewerId) {
      this.$nextTick(() => {
        addClick({ viewerId: viewerId }).then(({ data }) => {
          if (data && data.code === 0) {
            // Good
          } else {
            console.log('Error recording click')
          }
        })
      })
    },
    showViewer(model, isManualChange) {
      this.hasInitiatedViewer = false
      this.previousViewerId = this.viewerId
      if (this.selectedGroup && this.currentMaterials[this.selectedGroup]) {
        this.cachedModels.push({
          routingCode: this.emersyaId,
          selectedGroup: this.selectedGroup,
          currentMaterials: this.currentMaterials,
          materialGroups: this.materialGroups
        })
      }
      this.viewerId = model.viewerId
      if (isManualChange) {
        this.presetConfigurationName = ''
        this.presetGroupName = ''
        this.selectedGroup = ''
        this.updateURL()
        this.$forceUpdate()
      }
      let similarChachedModels = this.cachedModels.filter(
        (obj) => obj.routingCode == this.emersyaId
      )
      if (similarChachedModels.length > 0) {
        const latestCachedModel =
          similarChachedModels[similarChachedModels.length - 1]
        console.log(similarChachedModels, 'HAS BEEN LOADED BEFORE!')
        this.currentMaterials = latestCachedModel.currentMaterials
        this.selectedGroup = Object.keys(this.currentMaterials)[0]
        this.materialGroups = latestCachedModel.materialGroups
      }
      this.switchViewer(this.emersyaId)

      this.resetTimer()
    },
    switchViewer(routingCode) {
      const existingEmersyaScript = document.getElementById('emersya')
      if (existingEmersyaScript) {
        existingEmersyaScript.remove()
      }

      const emersyaScript = document.createElement('script')
      emersyaScript.setAttribute(
        'src',
        'https://cdn.emersya.com/f/emersyaLoader.js'
      )
      emersyaScript.setAttribute('type', 'text/javascript')
      emersyaScript.setAttribute('id', 'emersya')
      emersyaScript.setAttribute('routing', routingCode)
      emersyaScript.setAttribute('containerId', 'emersyaMainViewer')
      document.head.appendChild(emersyaScript)
    },
    backToMenu() {
      this.viewerId = ''
      this.currentProductNo = ''
      this.currentProductInfo = {
        productCategory: '',
        surface: '',
        gloss: '',
        productPerformance: '',
        hex: ''
      }
      this.hasInitiatedViewer = false
      this.currentMaterials = []
      this.materialGroups = []
      this.updateURL()
      this.resetTimer()
    },
    getAllMaterialGroups() {
      this.$nextTick(() => {
        getAllMaterialGroupsApi({
          sessionId: this.emersyaSessionId,
          projectCode: this.projectCode
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.materialGroups = data.materialGroups
            this.getProductInfoList()

            // console.log(data, 'data')
            // console.log(this.mobileLayout, 'ismobile')
            // console.log(this.selectedGroup, 'selectedgroup')
            // console.log(data.materialGroups, 'materialgroups0')
            // console.log(typeof data.materialGroups, 'materialgroups0')
            // console.log(Object.keys(data.materialGroups), 'materialgroups0')
            // console.log(this.currentMaterials, 'currentmaterials')

            if (this.mobileLayout && this.selectedGroup == '') {
              this.selectedGroup = Object.keys(data.materialGroups)[0]
            }
          }
        })
      })
    },
    getProductInfoList() {
      let productNos = []
      Object.keys(this.materialGroups).forEach((item) => {
        Object.keys(this.materialGroups[item]).forEach((product) => {
          let productNo = this.extractFirst8Digits(product)
          if (productNo && productNo.length > 2) {
            productNos.push(productNo)
          }
        })
      })

      this.$nextTick(() => {
        getProductInfoBulkApi({
          productNoList: productNos,
          brandId: this.brandId
        }).then(({ data }) => {
          if (data && data.code == 'C0') {
            this.cachedProductList = {}
            data.viewer.forEach((item) => {
              const key = this.extractFirst8Digits(item.productNo)
              this.cachedProductList[key] = item
            })
            console.log(this.cachedProductList)
          }
        })
      })
    },
    selectMaterial(groupName, configurationName) {
      emViewers['emersyaMainViewer'].setMaterialsGroup({
        configurationName: configurationName,
        groupName: groupName
      })
      this.currentMaterials[groupName] = configurationName
      this.currentProductNo = this.extractFirst8Digits(configurationName)
      this.selectedGroup = groupName
      this.getProductInfo()
      this.updateURL()
      this.$forceUpdate()
      this.resetTimer()
    },
    getCurrentMaterialGroups() {
      emViewers['emersyaMainViewer']
        .getCurrentMaterialsGroup()
        .then((data) => {
          console.log(data, 'currentmaterialgroups')
          this.currentMaterials = []
          data.forEach((element) => {
            this.currentMaterials[element.groupName] = element.configurationName
          })
        })
        .error(function (error) {
          console.log(error)
        })
    },
    clickedGroup(index, isMobile) {
      // console.log(this.materialGroups, 'materialGroups')
      // console.log(this.selectedGroup, 'selectedGroup')
      if (this.selectedGroup == index && !isMobile) {
        this.selectedGroup = ''
      } else {
        this.selectedGroup = index
      }
    },
    updateURL() {
      this.$router.replace({
        query: {
          brandId: this.brandId,
          viewerId: this.viewerId,
          groupName: Object.keys(this.currentMaterials).toString(),
          configurationName: Object.values(this.currentMaterials).toString()
        }
      })
    },
    getIsArAvailable() {
      emViewers['emersyaMainViewer']
        .getARAvailability()
        .then((data) => (this.isArAvailable = data))
        .error(function (error) {
          console.log(error)
        })
    },
    startAr() {
      emViewers['emersyaMainViewer'].startAr()
    },
    extractFirst8Digits(str) {
      let count = 0
      let result = ''
      for (let i = 0; i < str.length; i++) {
        if (!isNaN(parseInt(str[i]))) {
          result += str[i]
          count++
          if (count === 8) {
            break
          }
        }
      }

      return result
    },
    getProductInfo() {
      this.currentProductInfo = this.cachedProductList[this.currentProductNo]
        ? this.cachedProductList[this.currentProductNo]
        : {}

      // this.$nextTick(() => {
      //   getProductInfoBulkApi({
      //     productNoList: [this.currentProductNo],
      //     brandId: this.brandId
      //   }).then(({ data }) => {
      //     if (data && data.code == 'C0') {
      //       this.currentProductInfo = data.viewer[0]
      //     }
      //   })
      // })
    },
    downloadImage() {
      const processScreenshot = this.processScreenshot
      emViewers['emersyaMainViewer']
        .getScreenshot({
          width: 512,
          height: 512,
          transparentBackground: false
        })
        .then((data) => processScreenshot(data))
        .error(function (error) {
          console.log(error)
        })
    },
    processScreenshot(screenshot) {
      const a = document.createElement('a')
      a.href = `data:${screenshot}`
      a.download = `${this.currentViewer.viewerTitle}.png`
      document.body.appendChild(a)
      a.click()
    },
    toggleBottomBarModelsDisplay() {
      this.displayBottomModels = !this.displayBottomModels
      this.handleResize()
      emViewers['emersyaMainViewer'].resize()
      this.resetTimer()
    },
    toggleMobileBottomBarModelsDisplay() {
      this.displayMobileBottomModels = !this.displayMobileBottomModels
      if (this.displayMobileBottomModels) {
        if (this.displayMobileBottomColors) {
          this.displayMobileBottomColors = false
        }
        this.$refs.mobileBottomSelectMenu.style.bottom = '90px'
      } else {
        this.$refs.mobileBottomSelectMenu.style.bottom = '0px'
      }
      this.handleResize()
      emViewers['emersyaMainViewer'].resize()
      this.resetTimer()
    },
    toggleMobileBottomBarColorsDisplay() {
      this.resetTimer()

      this.displayMobileBottomColors = !this.displayMobileBottomColors
      if (this.displayMobileBottomColors) {
        if (this.displayMobileBottomModels) {
          this.displayMobileBottomModels = false

          this.$refs.mobileBottomSelectMenu.style.bottom = '0px'
        }
      }

      this.handleResize()
      emViewers['emersyaMainViewer'].resize()
    },
    toggleCustomizeDisplay() {
      this.displayCustomize = !this.displayCustomize
      this.handleResize()
      emViewers['emersyaMainViewer'].resize()
      this.resetTimer()
    },
    copyLink() {
      navigator.clipboard.writeText(this.link)
      this.$message({
        message: this.$t('copy_success'),
        type: 'success',
        duration: 1500
      })
    },
    startTimer() {
      // 如果30分钟没有碰过
      const timerLength = 1000 * 60 * 30
      this.timer = setInterval(() => {
        location.reload()
      }, timerLength)
    },
    resetTimer() {
      if (this.timer != null) {
        clearInterval(this.timer)
        this.timer = null
        this.startTimer()
      }
    }
  }
}
</script>

<style lang="scss">
.spacer {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;

  .noData {
    padding: 50px;
    font-size: 40px;
    text-align: center;
  }

  .brandLogoSelector {
    width: 125px;
    height: 75px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-size: 50px;
    text-align: center;
    margin-top: 4%;
  }

  .yituLogo {
    position: fixed;
    left: 50px;
    bottom: 60px;
    z-index: 10;
  }

  .yituLogoMobile {
    position: fixed;
    left: 10px;
    bottom: 110px;
    z-index: 10;
  }

  .industrySelectorArea {
    width: fit-content;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4%;
    cursor: pointer;

    .industrySelector {
      display: flex;
      justify-content: center;
      font-size: 20px;
      font-weight: bold;
      box-shadow: 4px 4px 10px #c0c0c0;
      border-radius: 15px;

      div {
        padding: 10px 20px 10px 20px;
        border: 1px solid #c0c0c0;
      }

      div:nth-child(-n + 1) {
        border-radius: 15px 0px 0px 15px;
      }

      div:last-child {
        border-radius: 0px 15px 15px 0px;
      }
    }
  }

  .selectedIndustry {
    background-color: #97c11c;
    color: #ffffff;
  }

  .unselectedIndustry {
    color: #000000;
    background-color: white;
  }

  .modelSelectionArea {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    padding: 30px;
    display: flex;
    flex-wrap: wrap;

    .modelSelectCard {
      height: 190px;
      width: 180px;
      margin: 10px;
      border-radius: 8px;
      cursor: pointer;
      opacity: 1;

      .modelTitle {
        margin-bottom: 8px;
        font-size: 20px;
        text-align: center;
        font-weight: bold;
      }

      .modelSelectImage {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 120px;
        height: 120px;
      }
    }

    .modelSelectCard:hover {
      opacity: 0.7;
    }
  }

  .modelSelectionAreaMobile {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;

    .el-card__body {
      padding: 5% !important;
    }

    .modelSelectCard {
      width: 30%;
      margin: 1.5%;
      border-radius: 5px;
      cursor: pointer;
      opacity: 1;

      .modelTitle {
        margin-bottom: 3px;
        font-size: 4vw;
        text-align: center;
        font-weight: bold;
        width: 100%;
      }

      .modelSelectImage {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 80%;
      }
    }

    .modelSelectCard:hover {
      opacity: 0.7;
    }
  }

  .modelSelectMenu {
    margin: 0px;
    position: absolute;
    width: 100%;
    bottom: 0px;
    white-space: nowrap;
    padding: 5px;
    z-index: 12;

    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    .modelSelectMenuArrow {
      width: fit-content;
      display: block;
      height: 18px;
      background-color: #97c11c;
      border-radius: 0px 5px 0px 0px;
      z-index: 99;
      color: white;
      padding-left: 5px;
      padding-right: 5px;
      cursor: pointer;

      div {
        display: inline-block;
        font-size: 12px;
        padding-top: 3px;
        padding-bottom: 3px;
      }
    }

    .modelSelectMenuBar {
      width: 100%;
      height: 2px;
      display: block;
      background-color: #97c11c;
    }

    .container {
      height: 115px;
      width: 100%;
      display: block;
      background: #ffffff;

      .modelSelectCard {
        display: inline-block;
        height: calc(90% - 20px);
        aspect-ratio: 1;
        margin: 0.2% 0.5% 0 0.5%;
        cursor: pointer;
        opacity: 1;
        padding: 0.5%;

        .modelTitle {
          margin-bottom: 3px;
          font-size: 12px;
          text-align: center;
          font-weight: bold;
          width: 100%;
        }

        .modelSelectImage {
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 100%;
        }
      }
    }

    .modelSelectCard:hover {
      opacity: 0.7;
    }
  }

  .mobileBottomSelectMenu {
    margin: 0px;
    position: fixed;
    width: 100%;
    bottom: 0px;
    white-space: nowrap;
    padding: 5px;
    z-index: 12;
    background-color: #dedede46;

    .menuSegment {
      position: relative;
      border-top: 1px solid rgb(225, 225, 225);
      height: 52px;
      display: block;

      .title {
        width: 100%;
        text-align: center;
        margin-top: 15px;

        .arrow {
          position: absolute;
          left: 12px;
          top: 15px;
        }
      }

      .colorsContent {
        height: 100px;
        margin: 5px -5px -5px -5px;
        background-color: #fff;

        .flexBoxArea {
          margin-left: -10px;
          margin-right: -10px;
          padding-left: 10px;
          padding-right: 10px;
          background: #fff;
          display: flex;
          flex-wrap: nowrap;
          width: auto;
          overflow-x: auto;

          ::-webkit-scrollbar {
            display: none;
          }

          -ms-overflow-style: none;
          /* IE and Edge */
          scrollbar-width: none;

          /* Firefox */
          .groupSelectCard {
            display: block;
            padding: 5px;
            padding-left: 10px;
            padding-right: 10px;
            border: 1px solid #97c11c;
            color: #97c11c;
            border-radius: 2px;
            margin: 3px;
          }

          .selected {
            border: 2px solid #97c11c;
            font-weight: bold;
            color: #fff;
            background-color: #97c11c;
          }

          .materialSelectCard {
            padding: 0px;
            margin: 5px;
          }

          .materialPreview {
            width: 40px;
            height: 40px;
          }
        }
      }

      .modelsContent {
        background-color: #fff;

        ::-webkit-scrollbar {
          display: none;
        }

        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
        height: 90px;
        display: flex;
        flex-wrap: nowrap;
        width: auto;
        overflow-x: auto;
        margin-top: 3%;
        margin-left: -10px;
        margin-right: -10px;
        padding-left: 10px;
        padding-right: 10px;
        background: #fff;

        .modelSelectCard {
          display: inline-block;
          height: 90%;
          aspect-ratio: 1;
          margin: 0.5% 0.5% 0 0.5%;
          cursor: pointer;
          opacity: 1;
          padding: 0.5%;

          .modelTitle {
            margin-bottom: 3px;
            font-size: 12px;
            text-align: center;
            font-weight: bold;
            width: 100%;
          }

          .modelSelectImage {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
          }

          .selected {
            // box-shadow:  #97c11c 0px 0px 30px -12px;
            border: 1px solid #98c11c77;
            border-radius: 5px;
            opacity: 0.7;
          }
        }
      }
    }
  }

  .empty {
    height: 200px;
    padding: 100px;
    display: block;
    margin: auto;
    text-align: center;
    color: #7d7d7d;
  }

  .modelViewer {
    position: relative;
  }

  .mobileTopMenu {
    display: flex;
    justify-content: space-between;
    background-color: #dedede46;
    height: 35px;
    width: 100%;
    color: #97c11c;
    font-weight: bold;
    z-index: 11;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
    border-bottom: 1px solid rgb(225, 225, 225);

    .titleText {
      font-size: 18px;
      margin: 2px;
      padding: 4px;
    }
  }

  .topMenu {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;

    display: flex;
    justify-content: space-between;

    .backButton {
      background-color: #ffffff;
      border: 1px solid #97c11c;
      font-weight: bold;
      border-radius: 8px;
      color: #97c11c;
      font-size: 20px;
      margin: 20px;
      padding: 0px;
      cursor: pointer;
      height: 56px;
      z-index: 11;

      .backArrow {
        background-color: #dedede;
        border-radius: 8px 0px 0px 8px;
        height: 100%;
        padding: 15px 3px 15px 3px;
        display: inline-block;
      }

      .backButtonText {
        display: inline-block;
        padding: 15px;
        height: 100%;
      }
    }

    .backButton:hover {
      background-color: #97c11c;
      color: #ffffff;

      .backArrow {
        background-color: #799a17;
      }
    }

    .rightMenu {
      z-index: 11;

      .modelTitle {
        font-size: 20px;
        width: 80%;
        margin: 20px;
        text-align: right;
        color: #799a17;
        font-weight: bold;
        z-index: 11;
      }

      .titleText {
        cursor: pointer;
        padding: 5px;
        background-color: #ffffff;
        border-radius: 5px;
      }

      .downloadButton {
        margin-right: 15px;
        margin-top: 5px;
        height: 60px;
        width: 60px;
        cursor: pointer;
      }
    }
  }

  .sharingWindowContentBlocker {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: white;
    z-index: 12;
    opacity: 0.5;
    top: 0;
    left: 0;
  }

  .sharingWindow {
    z-index: 13;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 176px;
    transform: translate(-50%, -80%);
    display: block;
    background-color: #ffffff;
    opacity: 1;
    border: 1px solid #97c11c;
    border-radius: 15px;
    padding: 10px;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;

    /* Standard syntax */
    .sharingTitle {
      position: relative;
      text-align: center;
      padding-top: 5px;
      color: #799a17;
    }

    .sharingCloseButton {
      position: absolute;
      right: 5px;
      top: 5px;
      margin: 0px;
      padding: 0px;
    }
  }

  .arButton {
    z-index: 12;
    position: absolute;
    display: flex;
    justify-content: space-around;
    color: white;
    background-color: #97c11c;
    padding: 1px;
    border-radius: 25px;
    font-weight: 450;
    left: 40%;
    top: 95%;
    cursor: pointer;
    font-size: 22px;
    transform: translate(-40%, -50%);
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
  }

  #emersyaMainViewer {
    display: block;
  }

  .iframeLoadingScreen {
    border: none;
    display: block;
    position: absolute;
    top: 0px;
    width: 100%;
    opacity: 0.5;
    padding-top: 10%;
    cursor: wait;
    z-index: 99;

    div {
      font-size: 36px;
      display: block;
      position: absolute;
      left: 50%;
      top: 45%;
      transform: translateX(-35%);
      background-color: #fff;
      padding: 5px 8px 5px 8px;
      opacity: 0.8;
    }
  }

  .productInfoMinimized {
    position: absolute;
    bottom: 20px;
    right: 15px;
    width: 300px;
    padding: 15px;
    background-color: #97c11c;
    color: #ffffff;
    display: flex;
    border-radius: 8px;
    cursor: pointer;

    .el-icon-arrow-up {
      position: absolute;
      font-size: 20px;
      right: 20px;
    }
  }

  .closedCustomize {
    cursor: pointer;
    z-index: 9999;
    height: 100%;
    width: 111px;
    border-right: 3px solid #97c11c;
    position: absolute;
    right: 0px;
    top: 0px;

    i {
      background-color: #97c11c;
      color: #ffffff;
      border-bottom-left-radius: 5px;
      padding: 3px;
      font-weight: bold;
      cursor: pointer;
      display: block;
      text-align: center;
    }
  }

  .materialSelectorArea {
    position: absolute;
    top: 20px;
    right: 15px;
    display: flex;
    flex-flow: column;
    width: 300px;
    height: 95%;
    border: 5px solid #ffffff;

    .materialBox {
      border-left: 1px solid #97c11c;
      border-right: 1px solid #97c11c;
      padding: 20px;
      flex: 1 1 auto;

      overflow-y: auto;

      .groupSelectCard {
        background-color: #fdfdfd;
        margin: 5px 0px 5px 0px;
        padding: 5px;
        border-radius: 5px;

        .groupTitle {
          display: block;
          position: relative;
          margin: 5px 5px 5px 10px;
          cursor: pointer;
        }

        .groupTitle:hover {
          font-weight: bold;
        }

        .materialArea {
          display: flex;
          flex-wrap: wrap;

          .materialSelectCard {
            cursor: pointer;
            width: 45%;
            margin: 2%;
            font-size: 10px;

            .materialPreview {
              margin-top: 3px;
              height: 80px;
              width: 100%;
            }
          }

          .materialSelectCard:hover {
            border: 1px solid #97c11c;
          }
        }
      }
    }

    .productInfoBox {
      border-left: 1px solid #97c11c;
      border-right: 1px solid #97c11c;
      padding: 20px;
      flex: 0 1 auto;

      .productInfo {
        margin-bottom: 4px;
      }

      .productInfoValue {
        text-align: right;
        font-size: 20px;
      }

      .productInfoKey {
        text-align: left;
        font-size: 18px;
      }

      .colorPreview {
        width: 100%;
        height: 70px;
        display: block;
        border: 1px dashed #97c11c;
      }
    }

    .sectionTitle {
      // auto height
      flex: 0 1 auto;
      background-color: #97c11c;
      width: 100%;
      color: #ffffff;
      font-weight: bold;
      border-radius: 8px;
      font-size: 20px;
      text-align: center;
      padding: 10px 0px 10px 0px;
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #c0c0c0;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #97c11c;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #799a17;
}

html,
body {
  height: 100%;
  margin: 0;
}
</style>