import request from '@/utils/request'

export function info (params) {
    return request({
      url: '/model/viewer/queryModelViewerInfo',
      method: 'post',
      params: params
    })
  }

// 获取列表
export function list (params) {
    return request({
      url: '/model/viewer/queryModelViewerList',
      method: 'post',
      data: params
    })
  }

  // 添加
export function add (params) {
    return request({
      url: '/model/viewer/addModelViewerData',
      method: 'post',
      data: params
    })
  }

    // 添加
export function update (params) {
    return request({
      url: '/model/viewer/updateModelViewerData',
      method: 'post',
      data: params
    })
  }

  // 删除
export function del (params) {
    return request({
      url: '/model/viewer/delModelViewerData',
      method: 'post',
      params: params
    })
  }

    // 删除
export function updateStatus (params) {
    return request({
      url: '/model/viewer/controlsModelViewerData',
      method: 'post',
      data: params
    })
  }